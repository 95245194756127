<template>
  <app-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full px-12">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold">Gastos</h1>

          <div class="flex flex-row items-center ml-auto">
            <sa-button
              type="primary"
              @click="showCreateExpenseDialog"
              class="items-center px-3 py-3 font-bold rounded"
              :disabled="!isProMembership"
            >
              Agregar Gasto
            </sa-button>
            <pro-link v-if="!isProMembership"></pro-link>
          </div>
        </div>
        <OnlyProInfo
          v-if="!isProMembership"
          text="El panel de Gastos no está incluido en la membresía que tienes
        actualmente, Si estás interesado en llevar el control de tus gastos
        puedes ir a"
        ></OnlyProInfo>
        <div class="flex flex-col space-y-6">
          <el-tabs v-model="status">
            <el-tab-pane label="Gastos Realizados" name="expenses" />
            <el-tab-pane name="pending">
              <template #label>
                Gastos Pendientes &nbsp;
                <el-badge :value="pending.length" class="ml-2">
                  <div></div>
                </el-badge>
              </template>
            </el-tab-pane>
          </el-tabs>
          <content-card>
            <expense-list
              v-if="status == 'expenses'"
              :entities="expenses"
              @on:load="onLoad"
              @on:edit="onEdit"
              @on:delete="onDelete"
            />
            <expense-list
              v-if="status == 'pending'"
              is-pending
              :entities="pending"
              @on:load="onLoad"
              @on:edit="onEdit"
              @on:delete="onDelete"
              @on:pay="onPay"
            />
          </content-card>
        </div>
      </div>
    </div>

    <el-dialog append-to-body :visible.sync="showPaymentDialog">
      <payment-expense-form
        @save:payment="handlePayment"
      ></payment-expense-form>
    </el-dialog>
  </app-layout>
</template>
<style scoped>
.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.card-container {
  display: inline-block;
}

.card {
  width: 300px; /* Ajusta el ancho según tus necesidades */
  margin-right: 20px; /* Espacio entre las tarjetas */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.card-content {
  padding: 10px;
}

.card-content span {
  display: block;
  margin-bottom: 5px;
}

.card-content span strong {
  color: #333;
}
</style>
<script>
import DialogExpenseService from "@/components/dialogs/Expense/service";
import ExpenseService from "@/services/ExpenseService";
import {
  ExpenseType,
  ExpenseTypesDict,
  ExpenseCategory,
  ExpenseCategoryDict,
  ExpensePendingPaymentId,
} from "@/constants";
import { EXPENSES_STORE } from "@/store/modules/expenses/mutation-types";
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";

export default {
  name: "ExpensesIndex",
  components: {
    OnlyProInfo: () => import("@/components/atoms/SaOnlyProInfo.vue"),
    ProLink: () => import("@/components/atoms/SaProLink.vue"),
    AppLayout: () => import("@/components/layouts/AppLayout"),
    ContentCard: () => import("@/components/molecules/ContentCard"),
    ExpenseList: () => import("@/views/app/expenses/components/ExpenseList"),
    PaymentExpenseForm: () =>
      import("@/components/dialogs/Expense/components/PaymentExpenseForm"),
  },
  data() {
    return {
      isProMembership: this.$store.state.account.membershipType === 2 || this.$store.state.account.membershipType === 3,
      expenseType: ExpenseType,
      expenseTypes: ExpenseTypesDict,
      expenseCategory: ExpenseCategory,
      expenseCategories: ExpenseCategoryDict,
      headerAppSubscription: null,
      search: {
        ExpenseType: null,
        ExpenseCategory: null,
        ExpenseSubcategory: null,
        ExpenseDate: null,
      },
      status: "expenses",
      showPaymentDialog: false,
      expenseToPayId: null,
    };
  },
  created() {
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(
      this.handleHeaderAppCommands
    );
  },
  destroyed() {
    this.headerAppSubscription.unsubscribe();
  },
  async mounted() {
    await this.onLoad(this.search);

    if (this.$route.params.id) {
      this.read(this.$route.params.id);
    }
  },
  computed: {
    pending() {
      return this.$store.state.expenses.entities.filter(
        (entity) => entity.paymentMethod === ExpensePendingPaymentId
      );
    },
    expenses() {
      return this.$store.state.expenses.entities.filter(
        (entity) => entity.paymentMethod !== ExpensePendingPaymentId
      );
    },
  },
  methods: {
    async read(expenseId) {
      const expense = this.$store.state.expenses.entities.find(
        (e) => e.id == expenseId
      );
      this.onEdit(expense);
    },
    async onLoad(search) {
      this.search = search;
      try {
        const expenses = await ExpenseService.getAll({
          businessInfoId: this.$store.state.business.id,
          ...this.search,
          ExpenseDate: !this.search.ExpenseDate
            ? undefined
            : this.$moment(this.search.ExpenseDate).format("MM/DD/YYYY"),
        });
        this.$store.dispatch(EXPENSES_STORE, {
          ...this.$store.state.expenses,
          entities: expenses,
        });
      } catch (error) {
        this.$toastr.e(error.message);
      }
    },
    showCreateExpenseDialog() {
      if (this.$store.state.business.id) {
        DialogExpenseService.show();
      } else {
        this.$toastr.i(
          "Tienes que estar en una sucursal para poder crear gastos"
        );
      }
    },
    onEdit(expense) {
      DialogExpenseService.show(expense);
    },
    onDelete(expenseId) {
      this.$confirm(`¿Está seguro que desea eliminar este gasto?`, {
        type: "warning",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
      }).then(async () => {
        await this.handleDelete(expenseId);
      });
    },
    async handleDelete(expenseId) {
      try {
        await ExpenseService.delete(expenseId);
        this.$store.dispatch(EXPENSES_STORE, {
          ...this.$store.state.expenses,
          entities: this.$store.state.expenses.entities.filter(
            (entity) => entity.id !== expenseId
          ),
        });
        this.$toastr.s("Se eliminó con exito");
      } catch (error) {
        this.$toastr.e(error.message);
      }
    },
    handleHeaderAppCommands({ type }) {
      switch (type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          this.onLoad(this.search);
          break;
        default:
      }
    },
    onPay(expenseId) {
      this.showPaymentDialog = true;
      this.expenseToPayId = expenseId;
    },
    async handlePayment(paymentMethodId) {
      try {
        const expenseDate = new Date();
        await ExpenseService.pay({
          expenseId: this.expenseToPayId,
          paymentMethodId,
          expenseDate: expenseDate.toISOString(),
        });
        this.$store.dispatch(EXPENSES_STORE, {
          ...this.$store.state.expenses,
          entities: this.$store.state.expenses.entities.map((entity) =>
            entity.id === this.expenseToPayId
              ? { ...entity, expenseDate, paymentMethod: paymentMethodId }
              : entity
          ),
        });
        this.$toastr.s("Se pagó con exito");
      } catch (error) {
        this.$toastr.e(error.message);
      }
      this.showPaymentDialog = false;
      this.expenseToPayId = null;
    },
  },
};
</script>
